import { asInt } from '@/utils';
import { createRouter, createWebHistory, type Router } from 'vue-router';
import { filterInterceptor, loggedInInterceptor, seoInterceptor, modalInterceptor, unsubscribeInterceptor } from './interceptors';

import { useMatomo } from '@/composables/useMatomo';
import { nextTick } from 'vue';

export interface Routed {
    router?: Router;
}

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/reset/',
            name: 'reset',
            component: () => import('../views/resetcookies/resetcookies.vue')
        },
        {
            path: '/:category?/',
            component: () => import('../views/grid/grid.vue'),
            name: 'grid',
            props: route => ({
                page: asInt(route.query.page) || 1,
                offset: asInt(route.query.offset) || 0,
                limit: asInt(route.query.limit) || 39,
                category: route.params.category,
                services: route.query.services,
                search: route.query.search,
                ages: route.query.ages
            })
        },
        {
            path: '/login/',
            component: () => import('../views/grid/grid.vue'),
            name: 'login',
            beforeEnter: modalInterceptor
        },
        {
            path: '/lostpassword/',
            component: () => import('../views/grid/grid.vue'),
            name: 'lostpassword',
            beforeEnter: modalInterceptor
        },
        {
            path: '/reset-password/:userId/:token/',
            component: () => import('../views/grid/grid.vue'),
            name: 'resetpassword',
            beforeEnter: modalInterceptor
        },
        {
            path: '/register/',
            component: () => import('../views/grid/grid.vue'),
            name: 'register',
            beforeEnter: modalInterceptor
        },
        {
            path: '/confirm/:userId/:token/',
            component: () => import('../views/register/confirm.vue'),
            name: 'registration-success'
        },
        {
            path: '/unsubscribe/:userId/:token/',
            component: () => import('../views/grid/grid.vue'),
            name: 'unsubscribe-success',
            beforeEnter: unsubscribeInterceptor,
            props: route => ({
                page: asInt(route.query.page) || 1,
                offset: asInt(route.query.offset) || 0,
                limit: asInt(route.query.limit) || 39,
                category: route.params.category,
                services: route.query.services,
                search: route.query.search,
                ages: route.query.ages
            })
        },
        {
            path: '/performer/:advert/profile/',
            component: () => import('../views/profile/profile.vue'),
            name: 'profile',
            props: route => ({
                advert: asInt(route.params.advert)
            })
        },
        {
            path: '/performer/:advert/:service/',
            component: () => import('../views/videochat/videochat.vue'),
            name: 'videochat',
            props: route => ({
                advert: asInt(route.params.advert),
                service: route.params.service
            })
        },
        {
            path: '/performer/:advert/teaser/',
            component: () => import('../views/teaser/index.vue'),
            name: 'teaser',
            props: route => ({
                advert: asInt(route.params.advert)
            })
        },
        {
            path: '/favourites/',
            component: () => import('../views/grid/favourites.vue'),
            name: 'favourites',
            props: route => ({
                page: asInt(route.query.page) || 1,
                offset: asInt(route.query.offset) || 0,
                limit: asInt(route.query.limit) || 40,
                search: route.query.search
            })
        },
        {
            path: '/payment/',
            component: () => import('../views/payment/payment.vue'),
            name: 'payment',
            beforeEnter: loggedInInterceptor
        },
        {
            path: '/payment-failure/',
            component: () => import('../views/payment/payment.vue'),
            name: 'paymentfailure',
            beforeEnter: loggedInInterceptor
        },
        {
            path: '/payment-success/',
            component: () => import('../views/grid/thankyou.vue'),
            name: 'thankyou',
            beforeEnter: loggedInInterceptor,
            props: route => ({
                page: asInt(route.query.page) || 1,
                offset: asInt(route.query.offset) || 0,
                limit: asInt(route.query.limit) || 6
            })
        },
        {
            path: '/promos/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'promos',
            meta: { title: true }
        },
        {
            path: '/contact/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'contact',
            meta: { title: true }
        },
        {
            path: '/cookies/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'cookies',
            meta: { title: true }
        },
        {
            path: '/terms/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'terms',
            meta: { title: true }
        },
        {
            path: '/privacy-policy/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'policy',
            meta: { title: true }
        },
        {
            path: '/help/',
            component: () => import('../views/textpages/textpages.vue'),
            name: 'help',
            meta: { title: true }
        },
        {
            path: '/my-account/',
            component: () => import('../views/my-account/my-account.vue'),
            name: 'account',
            beforeEnter: loggedInInterceptor,
            children: [
                {
                    path: 'overview/',
                    name: 'overview',
                    component: () => import('../views/my-account/overview/overview.vue')
                },
                {
                    path: 'edit-data/',
                    name: 'editdata',
                    component: () => import('../views/my-account/edit-data/edit-data.vue')
                },
                {
                    path: 'changepassword/',
                    name: 'changepassword',
                    component: () => import('../views/my-account/changepassword/changepassword.vue')
                },
                {
                    path: 'changeemail/',
                    name: 'changeemail',
                    component: () => import('../views/my-account/changeemail/changeemail.vue')
                },
                {
                    path: 'removal/',
                    name: 'removal',
                    component: () => import('../views/my-account/removal/removal.vue')
                },
                {
                    path: 'inbox/:view?/:advertNumber?/:thread?',
                    name: 'inbox',
                    component: () => import('../views/my-account/inbox/inbox.vue'),
                    props: route => ({
                        view: route.params.view || 'threads',
                        advertNumber: route.params.advertNumber ? asInt(route.params.advertNumber) : undefined,
                        thread: route.params.thread ? asInt(route.params.thread) : undefined,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 7
                    })

                    //inbox
                    //inbox/threads
                    //inbox/thread/:performer/:threadid
                    //inbox/compoose
                    //inbox/compose/:performer
                },
                {
                    path: 'gift-voucher/',
                    name: 'giftvoucher',
                    component: () => import('../views/my-account/giftvoucher/giftvoucher.vue')
                },
                {
                    path: 'avg/',
                    name: 'avg',
                    component: () => import('../views/my-account/avg/avg.vue')
                },
                {
                    path: 'news/',
                    name: 'news',
                    component: () => import('../views/my-account/news/news.vue'),
                    props: route => ({
                        forWhom: 'ROLE_CLIENT',
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 5
                    })
                },
                {
                    path: 'settings/',
                    name: 'settings',
                    component: () => import('../views/my-account/settings/settings.vue')
                },
                {
                    path: 'contentpurchases/',
                    name: 'contentpurchases',
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 10
                    }),
                    component: () => import('../views/my-account/contentpurchases/contentpurchases.vue')
                },
                {
                    path: 'account-history/',
                    name: 'accounthistory',
                    props: route => ({
                        page: asInt(route.query.page) || 1,
                        offset: asInt(route.query.offset) || 0,
                        limit: asInt(route.query.limit) || 20
                    }),
                    component: () => import('../views/my-account/account-history/account-history.vue')
                }
            ]
        },
        {
            path: '/:pathMatch(.*)*',
            name: '404',
            component: () => import('../views/textpages/textpages.vue')
        }
    ]
});

router.afterEach(async (to, from, failure) => {
    if(!failure) {
        // SEO for profile is set after performer data is fetched in profile component
        if(to.name === 'profile') return;

        const { trackPageView } = useMatomo();

        try {
            await seoInterceptor(to, from);
            await nextTick();
            trackPageView();
        } catch (error) {
            console.error('Error during SEO or analytics processing:', error)
        }
    }
});

router.beforeEach((to, from, next) => {
    filterInterceptor(to, from, next);
});

export default router;
