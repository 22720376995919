import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';

import * as Sentry from '@sentry/vue';

import App from './app.vue';
import router from './router';

import './styles/main.scss';
import i18n from './translations';

import filters from './filters';

import { useUserStore } from '@/stores/user';
import { getConfirmation, getParameterByName } from './utils';
import { usePerformerStore } from './stores/performer';
import { usePaymentStore } from './stores/payment';
import { useCamStore } from './stores/cam';
import { useMessageStore } from './stores/message';
import { useChatStore } from './stores/chat';
import { useToyStore } from './stores/toy';
import { useTeaserStore } from './stores/teaser';
import { useModalsStore } from './stores/modals';
import config from './config';
import { useVideoCallStore } from './stores/videocall';
import { useFilterStore } from './stores/filter';
import { useMatomo } from './composables/useMatomo';

const app = createApp(App);
const pinia = createPinia();
const { initMatomo } = useMatomo();
app.config.globalProperties.$filters = filters;

app.use(pinia);
app.use(router);
app.use(i18n);

pinia.use(({ store }) => {
    store.router = markRaw(router);
});

if (!import.meta.env.DEV) {
    Sentry.init({
        app,
        dsn: 'https://b0eb2af65750788920a9296a12ec28ba:36769bbaafdc9a76e441b15e45405f1d@o228560.ingest.sentry.io/4506813568974848',
        integrations: [
            Sentry.browserTracingIntegration({ router })
        ],
        tracesSampleRate: 0.1,
        release: 'c8ed3342d4c811ee8b3ce61fa477e685'
    });
}

initMatomo();

app.mount('#app');

startup();

async function startup() {
    //no need to await this.. independent of everything
    usePerformerStore().initialize();
    useCamStore().initialize();
    useVideoCallStore().initialize();
    usePaymentStore().initialize();
    useMessageStore().initialize();
    useChatStore().initialize();
    useToyStore().initialize();
    useTeaserStore().initialize();
    useFilterStore().initialize();

    const userStore = useUserStore();
    userStore.init();

    // SafeMode
    const safeMode = getParameterByName('safe') === 'true';
    const gotsafe = safeMode ? userStore.toggleSafeMode() : '';

    // Age+cookie check
    const { toggleModal } = useModalsStore();
    const cookieageName = `${config.StorageKey}.cookies`;
    if (!window.localStorage.hasOwnProperty(cookieageName)) {
        toggleModal('cookie18');
    }

    // Advertisement
    const fromAdvertiser = getParameterByName('utm_medium').toLowerCase() == 'advertising';
    let confirmation = getConfirmation();

    await userStore.authenticate(fromAdvertiser, confirmation);

    window.addEventListener('load', () => {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.src = 'https://cdn.pushcrew.com/js/f6f6ca2e4fdc1ebcb786d9fb600540f9.js';
        document.head.appendChild(script);
        window._pcq = window._pcq || [];
        window._pcq.push(['_currentTime', Date.now()]);
        window._pcq.push(['noTrigger', true]);
    })
}
